import Vue from 'vue'
//@ts-ignore
import Alertize from '@/components/Alertize.vue'
//@ts-ignore
import ReportForm from '@/views/Admin/Reports/Form/ReportForm.vue'
//@ts-ignore
import { getError } from '@/utils/resolveObjectArray'
//@ts-ignore
import { ReportingForm, ResourcesReportingForm, preparedFiltersEntitys } from '@/utils/reportData'
import { debounce, first, isEmpty, isNull } from 'lodash'
import { mapActions } from 'vuex'
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: 'Reporting',
	props: {},
	components: { Alertize, ReportForm },
	data: () => ({
		report: ReportingForm(),
		resources: ResourcesReportingForm(),
		filterEntitys: preparedFiltersEntitys(),
		account: null,
		accountSelected: null,
		terms: {
			advertiser: '',
			campaign: '',
			line_item: '',
			creative: '',
			account: '',
		},
		loading:{
			advertisers: false,
			campaigns: false,
			line_items: false,
			creatives: false
		}
	}),
	created() {
		this.$nextTick(async () => {
			await this.setLoadingData();
		});
	},
	async mounted() {
		this.$nextTick(async () => {
			await this.loadData();
			await this.setLoadingData();
		});
	 },
	computed: {
		getErrors() {
			return this.$store.state.proccess.errors
		},
		isAccountMaster(){
            return this.account.master_account;
        },
		getExternalIDAccount(){
			return this.account?.external_id;
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("account", ["getAccount"]),
		...mapActions("report", [
			"getDimMet", 
			"getAllReportType", 
			"getSchedulerTypes"
		]),

		getError(index: any) {
			return getError(this.getErrors, index)
		},

		async loadData(){
			await this.setLoadingData(TypeLoading.loading);
            this.account = await this.getAccount();
			this.accountSelected = [{id: this.account.external_id, value: this.account.name}];
			if(this.account.master_account){
				await this.fetchFilters("account", this.accountSelected);
			}
			await this.fetchFilters("advertisers", this.accountSelected);
			await this.setLoadingData();
        },

		async fetchFilters(entity: string, accounts: any){
			this.loading[entity] = true;
			let data = {
				type: this.filterEntitys[entity].type, 
				custom_key: this.filterEntitys[entity].key,
				accounts
			}
			if(this.filterEntitys[entity].requireFilter){
				data['filter'] = await this.getFilterForEntity(this.filterEntitys[entity].filter);
			}
			const result = await this.dispatchSearch(data);
			this.resources[this.filterEntitys[entity].resourceName] = result;
			this.loading[entity] = false;
		},

		async getFilterForEntity(entity: string){
			return this.report.filters[entity].map((f) => f.id);
		},

		async handleAccount(params: any){
			if(isEmpty(params)){
				this.accountSelected = [{id: this.account.external_id, value: this.account.name}];
			}else{
				this.accountSelected = params;
			}
		},

		// fetching

		async fetchResource(params: {
			resource: string
			field: string
			custom_key: string
			custom_value: string
		}) {
			if (!isEmpty(this.resources[params.field])) return

			await this.setLoadingData(TypeLoading.loading);

			const result: Array<any> = await this.dispatchSearch({
				type: params.resource,
				return_object: false,
				...params
			})

			this.resources[params.field] = result

			this.report[params.field] = first(result)?.id || ''

			if(params.field == "data_range"){
				this.report[params.field] = "7";
				this.resources[params.field] = this.resources[params.field].filter((d) => d.id != "today");
			}
			await this.setLoadingData();
		},

		async fetchFields(params: any) {

			const _prepare = {
				report_type: this.report.report_type,
				type: 'report_fields',
				custom_key: 'name',
				custom_value: 'description',
				...params
			}
			
			await this.setLoadingData(TypeLoading.loading);

			const result = await this.dispatchFields(_prepare)

			this.resources[params.field_type] = result

			await this.setLoadingData();
		},

		async fetchAllReportType(){
			await this.setLoadingData(TypeLoading.loading);

			await this.getAllReportType()
			.then((res) => {
				this.resources["report_all_type"] = res
				this.setLoadingData();
			})
			.catch((err) => {
				this.setLoadingData();
			});
		},

		async fetchDimMet(params: any) {

			const _prepare = {
				report_type: this.report.report_type,
				...params
			}
			await this.setLoadingData(TypeLoading.loading);
			await this.getDimMet(_prepare)
			.then((res) => {
				this.resources[params.field_type] = res
				this.setLoadingData();
			})
			.catch((err) => {
				this.setLoadingData();
			});
		},

		async fetchSchedulerTypes(field_type: string){
			await this.setLoadingData(TypeLoading.loading);

			await this.getSchedulerTypes()
			.then((res) => {
				this.resources[field_type] = res
				this.setLoadingData();
			})
			.catch((err) => {
				this.setLoadingData();
			});
		},

		async dispatchFields(params: {
			report_type: string
			field_type: string
			type: string
			custom_key?: string
			custom_value?: string
			return_object?: Boolean
		}) {
			return this.$store.dispatch('report/fields', params)
		},

		async dispatchSearch(params: any) {
			return this.$store.dispatch('report/search', {
				type: params?.type,
				term: this.terms[params?.type],
				return_object: params.return_object,
				custom_key: params.custom_key,
				custom_value: params.custom_value,
				account_id: params.account_id,
				filters: params?.filter,
				accounts: params?.accounts
			})
		},

		async handleCombo(key: string, eventW: boolean = false){
			//if(isEmpty(this.report.filters[this.filterEntitys[key].dependency])) return;
			if(!eventW) this.terms[this.filterEntitys[key].type] = "";
			await this.fetchFilters(key, this.accountSelected);
		},

		handleTerm(event: any) {
			this.terms[event.type] = event.term
		},

		handleRemove(event: any) {
			let root: Array<string> = ['dimension', 'metric']
			let index = -1

			if (root.includes(event.type)) {
				index = this.report[event.type].indexOf(event.item)
				if (index >= 0) this.report[event.type].splice(index, 1)
			} else {
				index = this.report.filters[event.type].indexOf(event.item)
				if (index >= 0) this.report.filters[event.type].splice(index, 1)
			}
		},

		async clearAllFilters(params: any){
			this.report.filters.advertisers = [];
			this.report.filters.line_items = [];
			this.report.filters.campaigns = [];
			this.report.filters.creatives = [];

			this.resources.advertisers = [];
			this.resources.campaigns = [];
			this.resources.creatives = [];
			this.resources.line_items = [];

			if(params.dm){
				this.resources.report_metrics = [];
				this.resources.report_dimensions = [];
				
				this.report.dimension = [];
				this.report.metric = [];
			}

			if(params.account){
				this.resources.account = [];
				this.report.filters.account = [];
			}

			if(this.account.master_account){
				await this.fetchFilters("account", this.accountSelected);
			}
		},

		async handleClearFilter(params: any){
			let values = this.filterEntitys[params].delete;
			if(!isEmpty(values)){
				values.forEach((e) => {
					this.resources[e] = [];
					this.report.filters[e] = [];
				});
			}
		},

		async handleVerifyFilter(params: any){
			if(!isEmpty(this.report.filters.advertisers) && params != "advertisers"){
				return false;
			}
			if(!isEmpty(this.report.filters.campaigns) && params != "campaigns"){
				return false;
			}
			if(!isEmpty(this.report.filters.creatives) && params != "creatives"){
				return false;
			}
			if(!isEmpty(this.report.filters.line_items) && params != "line_items"){
				return false;
			}
			return true;
		},

		// Clear all fields differences after each account selection
		clearAllDifferences(key) {
			this.report.filters[key] = this.report.filters[key].filter(({ id: id_filter }) => {
				return this.resources[key].some(({ id: id_resource}) => {
					return id_resource == id_filter
				})
			})
		},

		checkFields: debounce(async function(val, key) {
			await this.handleCombo(key, true)
			this.clearAllDifferences(key)
		}, 250)

	},
	watch: {

		async 'terms.advertiser'(val) {
			if(isEmpty(this.accountSelected)) return;
			await this.handleCombo("advertisers", true);
			//await this.fetchFilters("advertisers", this.accountSelected);
		},

		async 'terms.line_item'(val) {
			if(isEmpty(this.accountSelected)) return;
			await this.handleCombo("line_items", true);
			//await this.fetchFilters("line_items", this.accountSelected);
		},

		async 'terms.campaign'(val) {
			if(isEmpty(this.accountSelected)) return;
			await this.handleCombo("campaigns", true);
			//await this.fetchFilters("campaigns", this.accountSelected);
		},

		async 'terms.creative'(val) {
			if(isEmpty(this.accountSelected)) return; 
			await this.handleCombo("creatives", true);
			//await this.fetchFilters("creatives", this.accountSelected);
		},

		async 'accountSelected'(val) {
			this.checkFields(val, "advertisers")
		},

		async 'report.filters.advertisers'(val) {
			this.checkFields(val, "campaigns")
		},

		async 'report.filters.campaigns'(val) {
			this.checkFields(val, "line_items")
		},

		async 'report.filters.line_items'(val) {
			this.checkFields(val, "creatives")
		},
	},
})
