import Vue from "vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import ArrayListItem from "@/components/Content/ArrayListItem.vue";
// @ts-ignore
import { isEmpty, isUndefined, isNull } from "lodash";
//@ts-ignore
import { getError } from '@/utils/resolveObjectArray';
// @ts-ignore
import { prepareReportParams } from "@/utils/reportData";
import { mapActions } from "vuex";
import {
	isRequired,
	isAfterCompare,
	isMaxDays,
    isEmail
	//@ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default Vue.extend({
    name:"Scheduled",
    props:{
        report: {
			type: Object,
		},
		resources: {
			type: Object,
		},
    },
    components:{
        CardAutocomplete,
        CardTextField,
        CardActions,
        DatePicker,
        DataPickerStarTime,
        ArrayListItem
    },
    data: () => ({
        valid: true,
        accountMaster: false,
        startDateScheduler: new Date(),
        scheduler:{
            schedulerType: "",
            formatData: "",
            schedule_start_date: "",
            schedule_end_date:"",
            email_subject:"",
            emails: [],
            email_to: "",
            includeEmail: false
        },
        config:{
            showMsg: false,
            Msg: "",
        },
        rules:{
            start_date_rule: [],
            end_date_rule: [],
            subject_rule: []
        }
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            await this.fetchSchedulerTypes('scheduler_type');
            await this.loadData();
            await this.setValuesDefault();
        });
    },
    computed:{

        getErrors() {
			return this.$store.state.proccess.errors
		},

        getRules() {
			return {
				isRequired,
				isAfterCompare,
				isMaxDays,
                isEmail
			};
		},

        getRuleEmail(){
            if(isEmpty(this.scheduler.email_to)){
                return [];
            }
            return [this.getRules.isEmail(this.scheduler.email_to)]
        },

        getMinDate() {
			return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
		},

        getMinEndDate() {
			return this.moment(this.scheduler.schedule_start_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
		},

        getStartDateScheduler(){
            return this.moment(this.scheduler.schedule_start_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
        },

        getEndDateScheduler(){
            return this.moment(this.scheduler.schedule_end_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
        },

        isAccountMaster(){
            return this.accountMaster;
        }
    },
    methods:{

        ...mapActions("report", ["createSchedulerReport"]),
        ...mapActions("loading", ["setLoadingData"]),
        ...mapActions("account", ["getAccount"]),
        
        getError(index: any) {
			return getError(this.getErrors, index)
		},

        async loadData(){
            const response = await this.getAccount();
            this.accountMaster = response.master_account;
        },

        async findEmail(email: string){
            return this.scheduler.emails.find(m => m == email);
        },

        async setValuesDefault(){
            setTimeout(() =>{
                this.scheduler.formatData = this.report.type;
                if(!isEmpty(this.resources.scheduler_type)){
                    this.scheduler.schedulerType = this.resources.scheduler_type[0].id;
                };
            }, 1000);
        },

        async validate() {
			return await this.$refs.form.validate();
		},

        async handleAction(action: { type: any }) {
			try {
				switch (action.type) {
					case "create-scheduler":
                        await this.addRules();
						await this.CreateScheduler();
						break;
				}
			} catch (error) {
			}
		},

        async addRules(){
            this.rules.subject_rule = [this.getRules.isRequired];
            this.rules.start_date_rule = [this.getRules.isRequired];
            this.rules.end_date_rule = [this.getRules.isRequired,
                this.getRules.isAfterCompare(
                    this.scheduler.schedule_end_date,
                    this.scheduler.schedule_start_date
                ),
                this.getRules.isMaxDays(
                    this.scheduler.schedule_end_date,
                    this.scheduler.schedule_start_date,
                    90
            )];
        },

        async clearRules(){
            this.rules.subject_rule = [];
            this.rules.start_date_rule = [];
            this.rules.end_date_rule = [];
        },  

        async CreateScheduler(){
            try {
				if (!(await this.validate())) return;
                if(isEmpty(this.scheduler.emails)){
                    this.config.showMsg = true;
                    this.config.Msg = this.$t("report.scheduler.msgToEmail");
                    return;
                }
                this.config.Msg = "";
                this.config.showMsg = false;
				await this.setLoadingData(TypeLoading.loading);
                const dataSend = await this.GeneratedData();
                await this.createSchedulerReport(dataSend);
				await this.setLoadingData();
                this.$emit('update-list');
                await this.clearData();
			} catch (error) {
				await this.setLoadingData();
			}
        },

        async GeneratedData(){

            const payload = await prepareReportParams(this.report);

            const dataSend = {
                scheduler_type: this.scheduler.schedulerType,
                email_subject: this.scheduler.email_subject, 
                emails: this.scheduler.emails,
                schedule_start_date: this.getStartDateScheduler,
                schedule_end_date: this.getEndDateScheduler,
                include_account_email: this.scheduler.includeEmail ? 1 : 0,
            }

            const obj = {...payload, ...dataSend} 

            return obj;
        },

        async clearData(){
            await this.clearRules();
            this.scheduler.schedulerType = "";
            this.scheduler.formatData = "";
            this.scheduler.schedule_start_date = "";
            this.scheduler.schedule_end_date = "";
            this.scheduler.email_subject = "";
            this.scheduler.emails = [];
            this.scheduler.email_to = "";
            this.scheduler.includeEmail = false;
            await this.setValuesDefault();
        },

        async handleDelete(event: String){
            if(!isEmpty(event)){
                let idx = -1;
                this.scheduler.emails.forEach((element, index) => {
                    if(element == event) idx = index;
                });
                if(idx != -1){
                    this.scheduler.emails.splice(idx, 1);
                }
            }
        },

        async handleAppend(event: any){
            if (event instanceof KeyboardEvent) {
				event.preventDefault();
				event.stopPropagation();
				if (event.keyCode == 13) {
                    if(!isEmpty(this.scheduler.email_to)){
                        if(this.getRules.isEmail(this.scheduler.email_to) == true){
                            if(isUndefined(await this.findEmail(this.scheduler.email_to))){
                                this.scheduler.emails.push(this.scheduler.email_to);
                                this.scheduler.email_to = "";
                            }
                        }
                    }
				}
			}
        },

        async fetchSchedulerTypes(field_type: string){
            this.$emit("fetch-scheduler-type", field_type);
        },
    },
    watch:{
        async "scheduler.schedule_start_date"(val){
            if(val){
                this.rules.start_date_rule = [this.getRules.isRequired];
            }else{
                this.rules.start_date_rule = [];
            }
        },
        async "scheduler.schedule_end_date"(val){
            if(val){
                this.rules.end_date_rule = [this.getRules.isRequired,
                    this.getRules.isAfterCompare(
                        this.scheduler.schedule_end_date,
                        this.scheduler.schedule_start_date
                    ),
                    this.getRules.isMaxDays(
                        this.scheduler.schedule_end_date,
                        this.scheduler.schedule_start_date,
                        90
                )];
            }else{
                this.rules.end_date_rule = [];
            }
        },
    }
})