import Vue from "vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue"
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue"
// @ts-ignore
import { SortingOption, Paginated, ResultPaginate } from "@/interfaces/paginated";
// @ts-ignore
import { preparedColumnDefsGrid } from "@/utils/CustomizeViewData";
// @ts-ignore
import { mapActions } from 'vuex';
// @ts-ignore
import { isEmpty, isUndefined, isNull } from "lodash";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { SchedulerReportFilters } from "@/interfaces/report";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";

export default Vue.extend({
	name: "SchedulerReportList",
	props: {},
	components: { 
		GridTable,
		FilterGridAG,
		ActiveFieldTable,
		Separator
	},
	data: () => ({
		paginated: {
			page: 1,
			limit: 5,
		} as Paginated,
		filters: {},
		options: getDefaultSortingOption("id"),
		table: {
			items: [],
		},
		context: null,
		selectionRows: "single"
	}),
	created() {
		this.$nextTick(async () => {
		});
	},
	async mounted() {
		this.$nextTick(async () => {
			await this.getPaginated();
			this.context = { componentParent: this };
		});
	},
	computed: {

		showListReport(){
			return !isEmpty(this.getSchedulerReport);
		},

		getResultPaginate(): ResultPaginate {
			return this.$store.state.report.result_paginate;
		},

		prepareTableHeaders() {
			return [
				{
					text: this.$t("report.scheduler.table.subject"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "email_subject",
				},
				{
					text: this.$t("report.scheduler.table.report_type"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "report_type",
				},
				{
					text: this.$t("report.scheduler.table.start_date"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "start_date",
				},
				{
					text: this.$t("report.scheduler.table.end_date"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "end_date",
				},
				{
					text: this.$t("report.scheduler.table.status"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "status",
				},
                {
					text: this.$t("report.scheduler.table.created_user"),
					align: "start",
					sortable: false,
					filterable: false,
					value: "user_name",
				},
			];
		},

		getColumnsDef(){
            return preparedColumnDefsGrid(this.prepareTableHeaders, this.getConfigColumnDef);
        },

		getConfigColumnDef(){
			return {
				context: this.context,
				entityName: "Scheduler Report List",
				eventActions: false,
                flex: 1,
                resizableID: true,
			};
		},

		gerRowsData(){
            if(isEmpty(this.table.items)){
                return [];
            }
			return this.table.items;
        },

		getSchedulerReport(){
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("report", ["getSchedulerPaginated"]),

		updatePaginate(data: any) {
			this.paginated.page = data;
		},

		async getPaginated() {
			await this.setLoadingData(TypeLoading.loading);
			const response = await this.getSchedulerPaginated(await ParamService.getParams(
				this.paginated,
				this.filters,
				this.options
			));
			this.table.items = response.data;
			await this.setLoadingData();
		},

		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
		},

		async selectedOption(params: { options: SortingOption; filter: any }) {
			this.setFilter({ key: params.options.sort, value: params.filter });
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options,
			});
		},

		async updateParams(params: {
			filters: SchedulerReportFilters;
			options: SortingOption;
		}) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption("id");
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async updateTable(){
			await this.getPaginated();
		}
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
	},
});