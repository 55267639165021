
import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardSearcher from "@/components/Content/CardSearcher.vue";
// @ts-ignore
import CardChip from "@/components/Content/CardChip.vue";
// @ts-ignore
import CardList from "@/components/Content/CardList.vue";
// @ts-ignore
import CardGroupList from "@/components/Content/CardGroupList.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import SchedulerForm from "@/views/Admin/Reports/Form/SchedulerReportForm.vue";
// @ts-ignore
import SchedulerReportList from "@/views/Admin/Reports/Form/SchedulerReportList.vue";
// @ts-ignore
import { getError } from "@/utils/resolveObjectArray";
// @ts-ignore
import { prepareReportParams } from "@/utils/reportData";
// @ts-ignore
import {
	isRequired,
	isAfterCompare,
	isMaxDays,
	//@ts-ignore
} from "@/services/rule-services";
//@ts-ignore
import { EnumStatus, ReportGetParam, ResultDataEntity } from "@/interfaces/report";

import { debounce, find, includes, isEmpty, isNull, isUndefined } from "lodash";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { ReportStatus } from "@/interfaces/report";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const EXCLUDE_LINE_ITEM = ["historic_report"];
export const EXCLUDE_CREATIVES = ["historic_report"];

export default Vue.extend({
	name: "ReportForm",
	props: {
		report: {
			type: Object,
		},
		resources: {
			type: Object,
		},
		loadings: {
			type: Object,
		},
	},
	components: {
		Alertize,
		Separator,
		CardAutocomplete,
		CardSearcher,
		CardTextField,
		CardChip,
		CardList,
		CardActions,
		DatePicker,
		DataPickerStarTime,
		CardGroupList,
		SchedulerForm,
		SchedulerReportList,
	},
	data: () => ({
		valid: true,
		isRunOver: false,
		enabledScheduler: false,
		showFormScheduler: false,
		accountMaster: false,
		accountSelected: null,

		selectedItem: [],

		accountTerm: "",
		advertiserTerm: "",
		lineItemTerm: "",
		campaignTerm: "",
		creativeTerm: "",

		metricTerm: "",
		dimensionTerm: "",

		selected_all: {
			accounts: [],
			advertisers: [],
			line_items: [],
			campaigns: [],
			creatives: [],
			account: [],
		},
	}),
	created() {
		this.$nextTick(async () => {
			this.setLoadingData(TypeLoading.loading);
			//await this.fetchResource("report_type", "report_type");
			await this.fetchAllReportType();
			await this.fetchResource("report_data_range", "data_range");
			await this.fetchResource("report_format_types", "type");
			await this.loadData();
			await this.setLoadingData();
		});
	},
	async mounted() {},
	computed: {
		...mapGetters("report", ["getState"]),
		getReportData() {
			return this.getState("resultReport");
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},

		showLineItems() {
			return !EXCLUDE_LINE_ITEM.includes(this.report.report_type);
		},

		showCreatives() {
			return !EXCLUDE_CREATIVES.includes(this.report.report_type);
		},

		getRules() {
			return {
				isRequired,
				isAfterCompare,
				isMaxDays,
			};
		},

		getFilteredMetric() {
			const metric: Array<any> = this.resources.metric;
			const term: string = this.metricTerm;
			if (!term) return metric;
			return metric.filter((m: { value: string }) =>
				m.value.toLowerCase().includes(term.toLowerCase())
			);
		},

		getFilteredDimension() {
			const dimension: Array<any> = this.resources.dimension;
			const term: string = this.dimensionTerm;
			if (!term) return dimension;
			return dimension.filter((m: { value: string }) =>
				m.value.toLowerCase().includes(term.toLowerCase())
			);
		},

		getMetrics() {
			const metric: Array<any> = this.resources.report_metrics;
			const term: string = this.metricTerm;
			if (!term) return metric;
			return metric.filter((m: { name: string }) =>
				m.name.toLowerCase().includes(term.toLowerCase())
			);
		},

		getDimension() {
			const term: string = this.dimensionTerm;
			if (!term) return this.resources.report_dimensions;
			let obj = {};
			Object.keys(this.resources.report_dimensions).forEach((e) => {
				let val = this.resources.report_dimensions[e];
				let values = this.getValuesDimensions(term, val);
				if (!isEmpty(values)) {
					obj[e] = values;
				}
			});
			return obj;
		},

		isCustom() {
			return this.report.data_range === "custom";
		},

		showDimensions() {
			return !isEmpty(this.resources.report_dimensions);
		},

		isAccountMaster() {
			return this.accountMaster;
		},

		getMaxDate() {
			return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("account", ["getAccount"]),
		...mapActions("report", [
			"postData",
			"setState",
			"attemptGetReport",
			"download",
			"prepareConfigReportAttempt",
			"downloadReport",
		]),

		getValuesDimensions(term: any, arr: Array<any>) {
			return arr.filter((m: { name: string }) =>
				m.name.toLowerCase().includes(term.toLowerCase())
			);
		},

		showFormSchedulerReport() {
			this.showFormScheduler = this.enabledScheduler;
		},

		async clearErr(key) {
			if (this.getErrors.hasOwnProperty(key)) {
				this.getErrors[key] = [];
			}
		},

		async loadData() {
			const response = await this.getAccount();
			this.accountMaster = response.master_account;
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async handlefetchReport() {
			try {
				if (!(await this.validate())) return;

				this.enabledScheduler = false;

				const payload = await prepareReportParams(this.report);

				/**
				 * Enviar datos de report
				 * /api/reports
				 */
				const resultData: ResultDataEntity = await this.postData({
					type: "report",
					payload,
				});

				/**
				 * Guardar en el store
				 */
				this.setState({ key: "resultReport", value: resultData });

				// Preparar los parametros para obtener el binario de csv|xls|data report
				const payloadGetReport: ReportGetParam | undefined =
					await this.prepareConfigReportAttempt({
						result: resultData,
						type: this.report.type,
					});

				// Llamar a endpoint recursivo para obtener el binario de csv|xls|data report
				const resportData: EnumStatus =
					await this.attemptGetReport(payloadGetReport);

				this.enabledScheduler = resportData === EnumStatus.SUCCESS;
			} catch (error) {
				console.error(`${this.$options.name}::handlefetchReport`, {
					error,
				});
			}
		},

		async handleCombo(key: string) {
			this.$emit("fetch-combo", key);
		},

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		syncData(key: string, term: string) {
			this[`${key}Term`] = term;
		},

		handleRemove(event: any) {
			this.$emit("remove-item", event);
		},

		async isSelectedAll(value: Array<number>) {
			return includes(value, 0);
		},

		async handleSelectedAll(key: any, value: Array<any>) {
			this.selected_all[key] = value;

			const items: Array<any> = (await this.isSelectedAll(value))
				? this.resources[key]
				: [];

			this.report.filters[key] = items;
		},

		/**
		 * Actualizar el valor selected_all
		 * Detectar si todos los elementos fueron seleccionados
		 * @param key
		 * @param value
		 */
		async handleSelectedItem(key: any, value: Array<any>) {
			const equal = this.resources[key].length === value.length;
			if (value.length == 0) {
				this.selected_all[key] = [];
			} else {
				this.selected_all[key] = equal ? [0] : [];
			}
		},

		/**
		 *
		 * Fetch resources
		 *
		 * @param key
		 * @param value
		 * @param field
		 * @event $emit
		 */
		async fetchResource(
			key: string,
			field: string,
			custom_key: string = "extra",
			custom_value: string = "description"
		) {
			this.$emit(`fetch-resource`, {
				resource: key,
				field: field,
				custom_key: custom_key,
				custom_value: custom_value,
			});
		},
		async fetchFields(field_type: string) {
			this.$emit(`fetch-fields`, { field_type });
		},

		async fetchAllReportType() {
			this.$emit(`fetch-all-report-type`);
		},

		async fetchDimension(field_type: string) {
			this.$emit(`fetch-dim-met`, { field_type });
		},

		async fetchMetricas(field_type: string) {
			this.$emit(`fetch-dim-met`, { field_type });
		},

		async fetchSchedulerTypes(field_type: string) {
			this.$emit("fetch-scheduler-type", field_type);
		},

		getDisplayName(type: string, item: number) {
			const finded = find(this[type], function (t) {
				t.id == item;
			});
			return finded?.value;
		},

		/**HANDLERS */

		/**
		 * From CardActions - line item
		 * @param action
		 */
		async handleAction(action: { type: any }) {
			try {
				this.setLoadingData(TypeLoading.loading);
				switch (action.type) {
					case "run":
						await this.handlefetchReport();
						break;
					case "scheduled-report":
						this.showFormSchedulerReport();
						break;
				}
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},

		async updateList() {
			this.$refs.shcedulerList?.updateTable();
		},

		async clearFilters(params: any) {
			this.$emit("clear-all-filter", params);
		},

		async handleClearFilters(params: any) {
			this.$emit("handle-clear", params);
		},

		async updateAccount(params) {
			this.$emit("update-account", params);
		},

		async checkSelectedAll(params: string) {
			if (
				this.resources[params].length ==
				this.report.filters[params].length
			) {
				this.selected_all[params] = [0];
			} else {
				this.selected_all[params] = [];
			}
		},
	},
	watch: {
		advertiserTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "advertiser",
				term: val,
			});
		}, 500),

		lineItemTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "line_item",
				term: val,
			});
		}, 500),

		campaignTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "campaign",
				term: val,
			});
		}, 500),

		creativeTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "creative",
				term: val,
			});
		}, 500),

		accountTerm: debounce(async function (val: string) {
			//if (val.length < 2) return;
			this.$emit("update-term", {
				type: "account",
				term: val,
			});
		}, 500),

		"report.data_range"() {
			if (!this.isCustom) {
				this.report.start_date = undefined;
				this.report.end_date = undefined;
			}
		},

		/**
		 * Watch: advertisers
		 * @param val
		 */
		async "report.filters.advertisers"(val) {
			this.handleSelectedItem("advertisers", val);
		},

		/**
		 * Watch: line_items
		 * @param val
		 */
		async "report.filters.line_items"(val) {
			this.handleSelectedItem("line_items", val);
		},

		/**
		 * Watch: campaigns
		 * @param val
		 */
		async "report.filters.campaigns"(val) {
			this.handleSelectedItem("campaigns", val);
		},

		/**
		 * Watch: creatives
		 * @param val
		 */
		async "report.filters.creatives"(val) {
			this.handleSelectedItem("creatives", val);
		},

		/**
		 * Watch: account
		 * @param val
		 */
		async "report.filters.account"(val) {
			this.handleSelectedItem("account", val);
			this.$emit("update-account", val);
		},

		/**
		 * Watch: report type
		 * @param val
		 */
		async "report.report_type"(val) {
			this.clearFilters({ account: true, dm: true });
			this.accountSelected = null;
		},

		async accountSelected(val) {
			this.updateAccount(val);
			this.clearFilters({ account: false, dm: true });
			if (isNull(val)) {
				this.report.filters.account = [];
				return;
			}
			this.report.filters.account = [];
			this.report.filters.account.push(val);
		},

		async "report.start_date"(val) {
			if (val) {
				this.clearErr("start_date");
			}
		},

		async "report.end_date"(val) {
			if (val) {
				this.clearErr("end_date");
			}
		},

		/**
		 * Watch: resources
		 * @param val
		 */
		async "resources.advertisers"(val) {
			this.checkSelectedAll("advertisers");
		},

		async "resources.campaigns"(val) {
			this.checkSelectedAll("campaigns");
		},

		async "resources.line_items"(val) {
			this.checkSelectedAll("line_items");
		},

		async "resources.creatives"(val) {
			this.checkSelectedAll("creatives");
		},
	},
});
